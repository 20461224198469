






import { onMounted } from '@vue/composition-api';
import { useToolActions } from '@/store';

const { setLinearLoader } = useToolActions(['setLinearLoader']);

export default {
  setup(_props, ctx) {
    onMounted(() => {
      setLinearLoader({
        func: () =>
          new Promise<void>(resolve => {
            setTimeout(() => {
              ctx.root.$router.push({ name: 'login' });
              resolve();
            }, 2000);
          })
      });
    });
    return {};
  }
};
